import { Settings as defaults } from "./settings.default";
import merge from "lodash.merge";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterCircle,
} from "react-icons/ai";
import { PaymentTypeEnum } from "@application/types/PaymentType.enum";
import { LANGUAGE } from "@config/lang";

export const Settings = merge(defaults, {
  endpoint: "https://admin-pushime.altaviatravel.com",
  languages: [LANGUAGE.SQ, LANGUAGE.EN],
  encodeUrl: false,
  hashRouter: true,
  defaultLanguage: "sq",
  links: {
    top: ["phone", "email", "b2b", "lang"],
    menu: [],
  },
  menuLinks: [],
  googleAnalyticsTrackingId: null,
  contact: {
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.974981155598!2d21.157263076292637!3d42.661885571166465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549ee15a20fbdb%3A0x85a1217e0054225f!2sAltaVia%20Travel!5e0!3m2!1sen!2smk!4v1688920521697!5m2!1sen!2smk",
  },
  social: [
    {
      name: "Facebook",
      Icon: AiFillFacebook,
      url: "https://www.facebook.com/altaviatravel",
    },
    {
      name: "Twitter",
      Icon: AiFillTwitterCircle,
      url: "https://twitter.com/altavia_travel?lang=en",
    },
    {
      name: "Instagram",
      Icon: AiFillInstagram,
      url: "https://www.instagram.com/altavialcc/",
    },
  ],
  search: {
    charter: {
      displayDepartures: true,
    },
    hotel: {
      displayDepartures: false,
      minBookingStartDays: 0,
    },
  },
  booking: {
    show_extend_hotel_night: true,
    passengers: {
      require_passport_no: false,
    },
    payments: [
      {
        type: PaymentTypeEnum.Cash,
        label: "payments.cash",
        public: true,
        agent: false,
      },
      {
        type: PaymentTypeEnum.CreditCard,
        label: "payments.cc",
        public: false,
        agent: false,
      },
      {
        type: PaymentTypeEnum.Invoice,
        label: "payments.inv",
        public: false,
        agent: true,
      },
    ],
  },
  iframe: {
    allowBooking: true,
  },
});
